import * as React from 'react';
import { Component } from "react";
import styled from 'styled-components';
import { PageNavigatorDot } from '../atoms/navigators/PageNavigatorDot';
import { range } from '../../utils/range';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const DotItem = styled.span`
  margin-right: 12px;

  &:last-of-type{
    margin-right: 0px;
  }
`;

interface Property{
  dotCount: number;
  currentIndex: number;
  onClickDot: (index: number) => void;
}

export class PageNavigator extends Component<Property>{

  private renderDot(n: number): JSX.Element{
    return(
      <DotItem>
        <PageNavigatorDot
          isOn={n === this.props.currentIndex}
          onClick={() => this.props.onClickDot(n)}
        />
      </DotItem>
    );
  }

  public render(): JSX.Element{
    return(
      <Container>
        {range(0, this.props.dotCount).map(i => this.renderDot(i))}
      </Container>
    );
  }
}