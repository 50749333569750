import * as React from 'react';
import { Component } from 'react';
import rehypeReact from "rehype-react";
import { ImageSlide } from '../rehypes/ImageSlide';
import { Quote } from '../rehypes/Quote';
import { PlaneText } from '../rehypes/PlaneText';

interface Property{
  htmlAst: any;
  slug: string;
}

export class HtmlAstRenderer extends Component<Property>{

  public render(): JSX.Element | null{
    return new rehypeReact({
      createElement: React.createElement,
      components:{
        "slide": (props: any) => {
          return(
            <ImageSlide slug={this.props.slug} src={props.src}></ImageSlide>
          )
        },
        "quote": (props: any) => (
          <Quote from={props.from}>{props.children}</Quote>
        ),
        "text": (props: any) => (
          <PlaneText>{props.children}</PlaneText>
        ),
      },
    }).Compiler(this.props.htmlAst);
  }

}