import * as React from 'react';
import { PureComponent } from 'react';
import styled from 'styled-components';
import { AppColor } from '../../../constants/Color';

interface Props{
  date: Date;
}

const Dateblock = styled.p<{}>`
  color: ${AppColor.SECONDARY};
  font-size: 0.9rem;

  margin-bottom: 0.2rem;
`;

export class ArticleDate extends PureComponent<Props>{

  public render() : JSX.Element{
    return(
      <Dateblock>
        {this.props.date}
      </Dateblock>
    );
  }
}