import * as React from "react";
import Helmet from "react-helmet";
import { FunctionComponent, useEffect } from "react";
import * as hljs from "highlight.js";

export const CodeSyntaxHighlight : FunctionComponent = () =>{
  useEffect(() =>{
    hljs.initHighlighting();
  }, []);

  return (
    <div>
      <Helmet>
        <link rel="stylesheet" href="/styles/atom-one-dark.css"></link>
      </Helmet>
    </div>
  );
}