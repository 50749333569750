import * as React from 'react';
import { Component } from 'react';
import { graphql } from 'gatsby';

import Layout from "../../components/layout";
import { ArticleTitle } from "../atoms/headings/ArticleTitle";
import { HorizontalSeparator } from '../atoms/separators/HorizontalSeparator';
import { ArticleDate } from '../atoms/texts/ArticleDate';
import { SEO } from '../seo';
import { CodeSyntaxHighlight } from '../helmets/CodeSyntaxHighlight';
import { HtmlAstRenderer } from '../organisms/HtmlAstRenderer';
import { Iframely } from '../helmets/Iframely';
import { ShareBelt } from '../organisms/ShareBelt';

export default class Document extends Component<any>{

  private getUrl(post: any): string{
    const slug: string = post.fields.slug;
    const home = this.props.data.site.siteMetadata.home;
    
    return `${home}/documents${slug}`;
  }

  public render() : JSX.Element{
    const post = this.props.data.markdownRemark;

    const coverImage = post.frontmatter.cover ? post.frontmatter.cover.childImageSharp.fluid.src : undefined;

    const description = post.frontmatter.description || post.excerpt || ' ';

    return(
      <Layout>
        <SEO
          title={post.frontmatter.title}
          description={description}
          isArticle={true}
          cover={coverImage}
        />
        <CodeSyntaxHighlight />
        <Iframely />
        <article>
          <ArticleTitle text={post.frontmatter.title} />
          <ArticleDate date={post.frontmatter.date} />
          <HorizontalSeparator />
          <HtmlAstRenderer htmlAst={post.htmlAst} slug={post.fields.slug} />
          <ShareBelt title={post.frontmatter.title} url={this.getUrl(post)} />
        </article>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query DocumentBySlug($slug: String!) {
    site{
      siteMetadata{
        home
      }
    }
    markdownRemark(
      fileAbsolutePath: {regex: "/documents//"},
      fields: { slug: { eq: $slug } }
    ) {
      id
      excerpt(pruneLength: 160)
      htmlAst
      frontmatter {
        title
        date(formatString: "MM/DD YYYY")
        description
        cover{
          relativePath
          childImageSharp{
            fluid(maxWidth: 640, quality: 90){
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      fields {
        slug
      }
    }
  }
`