import * as React from 'react';
import { PureComponent } from 'react';
import styled from 'styled-components';
import { AppColor } from '../../../constants/Color';
import { ChevronRight } from "@styled-icons/fa-solid/ChevronRight";

interface Props{
  onClick: () => void;
}

const Button = styled.button<{}>`
  border: none;

  width: 24px;
  height: 24px;

  background-color: transparent;
  outline-color: transparent;

  padding: 0px;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
`;

const Chevron = styled(ChevronRight)<{}>`
  width: 100%;
  height: 100%;

  color: ${AppColor.SECONDARY};

  &:hover{
    color: ${AppColor.PRIMARY};
  }
`;

export class PageNavigatorButtonRight extends PureComponent<Props>{

  public render() : JSX.Element{
    return(
      <Button onClick={()=>this.props.onClick()}>
        <Chevron />
      </Button>
    );
  }
}