import * as React from 'react';
import { Component } from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from 'styled-components';
import { PageNavigator } from '../molecules/PageNavigator';
import { PageNavigatorButtonLeft } from '../atoms/navigators/PageNavigatorButtonLeft';
import { PageNavigatorButtonRight } from '../atoms/navigators/PageNavigatorButtonRight';
import { SlideImage } from '../atoms/images/SlideImage';
import { range } from '../../utils/range';

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;

  cursor: pointer;
`;

const NavigatorContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 48px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const NavigatorButton = styled.span`
  margin-left: 16px;
  margin-right: 16px;
`;

interface Property{
  src: any;
  slug: string;
}

interface State{
  currentIndex: number;
}

export class ImageSlide extends Component<Property, State>{

  private imageCount: number;

  constructor(props: Property){
    super(props);
    this.state = {
      currentIndex: 0,
    }
    this.imageCount = this.srcList.length;
  }

  private get srcList(): any[] {
    const srcString: string = this.props.src as string;
    return srcString.split(",")
      .map(x => x.trim())
      .map(x => this.props.slug + x)
      .map(x => this.getImage(x))
      .filter(x => x)
  }

  private get height(): number{
    return Math.max(...this.srcList.map(s => s.node.childImageSharp.sizes.presentationHeight));
  }

  private getImage(src: string): any{
    const files = useStaticQuery(graphql`
      query {
        images: allFile {
          edges {
            node {
              id
              childImageSharp {
                sizes {
                  ...GatsbyImageSharpSizes
                  presentationWidth
                  presentationHeight
                }
              }
              relativePath
              name
            }
          }
        }
      }
    `);

    return files.images.edges.find(n => src.includes(n.node.relativePath));
  }

  private updateCurrentIndex(index: number){
    this.setState({currentIndex: index});
  }

  private nextImage(){
    let targetIndex: number = this.state.currentIndex + 1;
    if(targetIndex >= this.imageCount){
      targetIndex = 0;
    }
    this.updateCurrentIndex(targetIndex);
  }

  private prevImage(){
    let targetIndex: number = this.state.currentIndex - 1;
    if(targetIndex < 0){
      targetIndex = this.imageCount - 1;
    }
    this.updateCurrentIndex(targetIndex);
  }

  private renderImage(index: number): JSX.Element{
    return(
      <SlideImage
        image={this.srcList[index].node.childImageSharp.sizes}
        alt={this.srcList[index].node.name}
        height={this.srcList[index].node.childImageSharp.sizes.presentationHeight}
        width={this.srcList[index].node.childImageSharp.sizes.presentationWidth}
        isShow={index === this.state.currentIndex}
      />
    );
  } 

  public render(): JSX.Element{
    return(
      <div>
        <ImageContainer
          onClick={() => this.nextImage()}
          style={{
            height: this.height,
          }}
        >
          {range(0, this.imageCount).map(i => this.renderImage(i))}
        </ImageContainer>
        <NavigatorContainer>
          <NavigatorButton>
            <PageNavigatorButtonLeft
              onClick={() => this.prevImage()}
            />
          </NavigatorButton>
          <PageNavigator
            currentIndex={this.state.currentIndex}
            dotCount={this.imageCount}
            onClickDot={(n: number) => this.updateCurrentIndex(n)}
          />
          <NavigatorButton>
            <PageNavigatorButtonRight
              onClick={() => this.nextImage()}
            />
          </NavigatorButton>
        </NavigatorContainer>
      </div>
    )
  }
}