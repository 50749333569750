import * as React from "react";
import { FunctionComponent } from "react";
import styled from "styled-components";
import { Twitter } from "@styled-icons/fa-brands/Twitter";
import { BlandColor, AppColor } from "../../../constants/Color";
import { useStaticQuery, graphql } from "gatsby";

interface Property{
  shareUrl: string;
  title: string;
}

const LinkButton = styled.a<{}>`
  width: 100%;
  height: 100%;

  background-color: white;

  border-style: solid;
  border-color: ${AppColor.PRIMARY};
  border-width: 1px;
  border-radius: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  text-decoration: none;

  &:hover{
    background-color: ${AppColor.PRIMARY_TRANS};
  }
`;

const Icon = styled(Twitter)<{}>`
  width: 50%;
  height: 50%;

  color: ${BlandColor.TWITTER};
`;

export const TwitterShareButton: FunctionComponent<Property> = (props: Property) =>{

  const encUrl = encodeURI(props.shareUrl);
  const encText = encodeURI(`Manicreator | ${props.title}`);

  const twitterUrl = `https://twitter.com/share?url=${encUrl}&text=${encText}`;

  return (
    <LinkButton href={twitterUrl} target="_blank">
      <Icon />
    </LinkButton>
  );
}