import * as React from "react";
import {Component} from "react";
import Helmet from "react-helmet";

export class Iframely extends Component{

  componentDidMount(){
    const iframely = (window as any).iframely;
    if(iframely){
      iframely.load();
    }
  }

  public render(): JSX.Element{
    return(
      <Helmet>
        <script type="text/javascript" src="https://cdn.iframe.ly/embed.js" />
      </Helmet>
    )
  }
}