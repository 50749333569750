import * as React from 'react';
import { PureComponent } from "react";
import Img from "gatsby-image/withIEPolyfill"
import styled from 'styled-components';

const StyledImg = styled(Img)`
  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 0;

  .gatsby-image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    z-index: -1;
  }
`;

interface Property{
  image : any;
  alt: string;
  width: number;
  height: number;
  isShow: boolean;
}

export class SlideImage extends PureComponent<Property>{

  public render() : JSX.Element | null{
    
    if(!this.props.image) return null;

    return(
      <StyledImg
        style={{
          width: this.props.width,
          height: this.props.height,
          display: this.props.isShow ? "block" : "none",
        }}
        alt={this.props.alt}
        sizes={this.props.image}
        objectFit="contain"
      />
    );
  }
}