import * as React from "react";
import { FunctionComponent } from "react";
import styled from "styled-components";
import { TwitterShareButton } from "../atoms/share-buttons/TwitterShareButton";
import { FacebookShareButton } from "../atoms/share-buttons/FacebookShareButton";

const Container = styled.div`
  box-sizing: border-box;

  padding-top: 1em;
  padding-bottom: 1em;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const Button = styled.span`
  width: 32px;
  height: 32px;

  margin-left: 8px;
`;

interface Property{
  url: string;
  title: string;
}

export const ShareBelt: FunctionComponent<Property> = (props: Property) =>{

  return(
    <Container>
      <Button>
        <TwitterShareButton shareUrl={props.url} title={props.title} />
      </Button>
      <Button>
        <FacebookShareButton shareUrl={props.url} />
      </Button>
    </Container>
  );
}