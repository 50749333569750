import * as React from "react";
import { FunctionComponent } from "react";
import styled from "styled-components";
import { AppColor } from "../../constants/Color";

const StyledBox = styled.div<{}>`
  box-sizing: border-box;

  background-color: ${AppColor.QUOTE_BACKGROUND};

  padding: 2em;

  margin-top: 1em;
  margin-bottom: 1em;
`

const From = styled.div`
  box-sizing: border-box;
  margin-top: 1em;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

type Property = {
  from: string,
}

export const Quote: FunctionComponent<Property> = (props) =>(
  <StyledBox>
    {props.children}
    <From>
      <a href={props.from}>{props.from}</a>
    </From>
  </StyledBox>
);