import * as React from "react";
import { FunctionComponent } from "react";
import styled from "styled-components";
import { FacebookF } from "@styled-icons/fa-brands/FacebookF";
import { BlandColor, AppColor } from "../../../constants/Color";

interface Property{
  shareUrl: string;
}

const LinkButton = styled.a<{}>`
  width: 100%;
  height: 100%;

  background-color: white;

  border-style: solid;
  border-color: ${AppColor.PRIMARY};
  border-width: 1px;
  border-radius: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  text-decoration: none;

  &:hover{
    background-color: ${AppColor.PRIMARY_TRANS};
  }
`;

const Icon = styled(FacebookF)<{}>`
  width: 50%;
  height: 50%;

  color: ${BlandColor.FACEBOOK};
`;

export const FacebookShareButton: FunctionComponent<Property> = (props: Property) =>{
  const encUrl = encodeURI(props.shareUrl);

  const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encUrl}`;

  return (
    <LinkButton href={facebookUrl} target="_blank">
      <Icon />
    </LinkButton>
  );
}