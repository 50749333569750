import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { AppColor } from "../../constants/Color";

const StyledPre = styled.pre<{}>`
  box-sizing: border-box;

  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  padding: 0.5rem;
  
  background-color: ${AppColor.QUOTE_BACKGROUND};
  font-family: monospace, monospace;
  font-size: 1em;

  border-radius: 2px;
`;

export const PlaneText: FunctionComponent = (props) =>{
  const text: string = props.children.toString().trim();

  return(
    <StyledPre>
      {text}
    </StyledPre>
  );
};