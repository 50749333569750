import * as React from 'react';
import { Component } from 'react';
import styled from 'styled-components';
import { AppColor } from '../../../constants/Color';

const OnDot = styled.div<{}>`
  background-color: ${AppColor.PRIMARY};

  width: 12px;
  height: 12px;

  border-radius: 100%;

  cursor: pointer;
`;

const OffDot = styled.div<{}>`
  background-color: ${AppColor.SECONDARY_TRANS};

  width: 12px;
  height: 12px;

  border-radius: 100%;

  cursor: pointer;
`;

interface Property{
  onClick: () => void;
  isOn: boolean;
}

export class PageNavigatorDot extends Component<Property>{

  private renderOn(): JSX.Element{
    return(
      <OnDot onClick={this.props.onClick} />
    );
  }

  private renderOff(): JSX.Element{
    return(
      <OffDot onClick={this.props.onClick} />
    )
  }

  public render(): JSX.Element{
    if(this.props.isOn){
      return this.renderOn();
    }
    else{
      return this.renderOff();
    }
  }
}